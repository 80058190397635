.header {
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(241, 131, 67, 0.066734);
}
.messages-button {
  position: relative;
  span {
    position: absolute;
    top: 5px;
    right: 0;
    background-color: #f18343;
    color: #f18343;
    padding: 2px;
    height: 10px;
    border-radius: 10px;
    text-align: center;
    line-height: 8px;
    font-size: 10px;
  }
}
/*nav */
nav.navbar {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  box-shadow: 0px 1px 5px rgba(241, 131, 67, 0.066734);
}
.navbar .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
  padding-bottom: 10px;
  padding-top: 10px;
}
nav .navbar-nav > li.nav-item {
  position: relative;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #f18343;
  background: none;
}

.border-gradient {
  box-shadow: 0px 2px 5px rgba(241, 131, 67, 0.066734);
}

//Text

.dropdown {
  .user-avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    object-fit: contain;
  }
  .e-mail {
    padding: 0 1rem;
    font-weight: bold;
    color: #6b6b6b;
  }
  .user-info {
    max-width: 5rem;
    .avatar-text {
      font-weight: 500;
    }
  }
}

.text-truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .navbar-light .navbar-nav .nav-link.active::before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -18px;
    left: 0;
    background: #f18343;
  }
}

.header-dropdown {
  .dropdown-item {
    padding: 0 1rem;
    color: #6b6b6b;
    cursor: default;
    a,
    button {
      color: #6b6b6b;
      text-decoration: none;
      &:hover {
        color: hsl(0, 0%, 70%);
      }
    }
    .btn.px-0 {
      width: 100%;
      text-align: start;
    }
    &:hover {
      background-color: #fff !important;
    }
  }
  .dropdown-divider {
    margin: 0.5rem 1rem;
  }
}
