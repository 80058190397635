.custom-input:focus-within {
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    border-bottom: 1px solid #dc3545;
    border-top: 1px solid #dc3545;
  }

  input {
    box-shadow: unset !important;
    border: 1px solid #f18343;
  }
  button {
    border-bottom: 1px solid #f18343;
    border-top: 1px solid #f18343;
  }
  button:last-child {
    border-right: 1px solid #f18343;
  }
}
