/* colors */
.status-in-progress {
  background: rgba(67, 192, 241, 0.18);
  color: #2289b1;
  border: 0.5px solid rgba(67, 192, 241, 0.38);
}
.status-pending {
  background: rgba(241, 131, 67, 0.177393);
  color: #ef6f24;
  border: 0.5px solid rgba(241, 131, 67, 0.384124);
}
.status-in-rejected {
  background: rgba(241, 131, 67, 0.177393);
  color: #ef6f24;
  border: 0.5px solid rgba(241, 131, 67, 0.384124);
}
.status-accepted {
  background: rgba(241, 131, 67, 0.177393);
  color: #ef6f24;
  border: 0.5px solid rgba(241, 131, 67, 0.384124);
}

.main-container {
  min-height: calc(100vh - 85px);
  margin: 0;
  font-size: 14px;
  color: #333333;
  background-color: #f2f2f2;
  font-family: 'Roboto', sans-serif;
}
/* utility */
.s-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.c-padding {
  padding: 20px;
}
.cx-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.cy-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
.c-margin {
  margin: 20px;
}
.cx-margin {
  margin-left: 20px;
  margin-right: 20px;
}
.cy-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.w-320px {
  width: 325px;
}
.sep-left {
  border-right: 2px solid rgba(151, 151, 151, 0.19564);
  margin-right: 25px;
  padding-right: 25px;
}
.sep-sm-left {
  border-right: 2px solid rgba(151, 151, 151, 0.19564);
  margin-right: 10px;
  padding-right: 10px;
}
.left-bar {
  border-radius: 2px 4px 4px 2px;
  border-left: 3px solid #f18343;
}
.imp-info {
  border: 1px solid #f18343;
  border-radius: 2px 4px 4px 2px;
  border-left: 3px solid #f18343;
  padding: 16px 32px 16px 50px;
  background: #fefaf7;
  margin: 16px 0 26px 0;
  line-height: normal;
}
.b-detail + .seprator {
  margin: 10px 18px 0px;
}
.seprator {
  width: 100%;
  border-bottom: 2px solid #ededed;
}
.table-data {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 4px;
}
.notification-count {
  display: block;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  text-align: center;
  background: #fdf2eb;
  color: #f4b590;
  border-radius: 15px;
  position: absolute;
  top: 18px;
  right: 50px;
}
.tost-notication {
  position: absolute;
  top: 100px;
  right: 0;
  background: #f18343;
  color: #fff;
  padding: 16px;
  z-index: 11;
}
ul.nav.nav-pills > li {
  position: relative;
}
.saved-count {
  background: #f18343;
  color: #fff;
  display: block;
  width: 21px;
  height: 13px;
  border-radius: 6px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: -9px;
  line-height: normal;
  right: 0;
}
// [type="range"] {
//   width: 100%;
//   height: 8px;
//   border-radius: 4px;
//   padding: 0;
//   // margin: 14px 0;
//   background: #dee2e6;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// [type="range"]::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background: #fff;
//   border: 1px solid #f18343;
//   box-shadow: #f18343 1px 0px 6px;
//   cursor: pointer;
// }
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1257px;
  }
}

/*colors & backgrund*/
.bg-white {
  background: #fff;
}
.light-bg {
  background: #f0f0f063;
}
.no-bg {
  background: unset;
  background-color: unset;
}
.card-status.bg-info {
  background: rgba(67, 192, 241, 0.18) !important;
  color: #2289b1;
}
.card-status.bg-danger {
  background: rgba(241, 131, 67, 0.177393) !important;
  color: #ef6f24;
}
/* accordion */
.badge {
  background-color: #e6e6e6;
  color: #333;
  font-weight: normal;
  margin: 4px;
  font-size: 12px;
}
.badge-medium {
  padding: 0.4em 0.6em;
}
.badage-list .badge:first-child {
  margin-left: 0;
}
.badage-list .badge:last-child {
  margin-right: 0;
}
.badage-list {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.2rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badage-list .badge .badge-close {
  opacity: 0;
  width: 10px;
  transition: 0.15s ease-in-out;
}
.badage-list .badge:hover .badge-close {
  opacity: 1;
  width: 10px;
  transition: 0.15s ease-in-out;
  animation-timing-function: ease-in-out;
}

.accordion-padding {
  padding: 10px;
}
.accordion-controller .card .card-header {
  padding: 0;
  background: #fff;
  border: none;
}
.accordion-controller.no-sidebar .card-header {
  padding: 0;
}
.accordion-controller .card .card-header > h5 {
  font-size: 16px;
}
.accordion-controller .card .card-header > h5 .accordion-button {
  font-size: 16px;
  font-weight: 600;
}
.accordion-controller:not(.no-sidebar) .card .card-header {
  padding: 0;
  border-bottom: dashed 1px #cdcdcd;
}
.accordion-controller .card {
  border-radius: 0;
  border: none;
  position: relative;
}
.accordion-button::after {
  display: inline;
  width: 1.25rem;
  position: absolute;
  right: 22px;
  height: 1.25rem;
  content: ' ';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button-sm::after {
  background-size: 1rem;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) translateX(3px);
}
.accordion-controller:not(.no-sidebar) .card {
  padding: 1px 26px;
}
.accordion-controller .card-body {
  padding: 0;
}
.accordion-container {
  // overflow-y: scroll;
  max-height: calc(100vh - 210px);
}
// .accordion-controller .card:last-child .card-header {
//   border: none;
// }
button[data-toggle='collapse'] {
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 12px 0px;
  font-size: unset;
}
/* tabs */

.nav-link {
  color: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f18343;
  background: none;
  border-radius: 0;
  border-bottom: 3px solid #f18343;
}
.nav-link:focus,
.nav-link:hover {
  color: #f18343;
}
#pills-tab li {
  padding-right: 15px;
}
#pills-tab li a {
  font-size: 16px;
  padding: 0 0 20px 0;
}
ul.nav.nav-pills > li > a {
  padding: 0 15px 20px 16px;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
/* card */
.card-status {
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.card-title .card-subtitle {
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}
.card-border-bottom {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  margin-bottom: 16px;
}
.card .job-detail .b-detail {
  padding-bottom: 24px;
}
.job-card {
  margin-bottom: 0px !important;
}
.card-body {
  padding: 24px 19px;
}
.side-card .job-detail {
  padding: 16px 8px 0px 12px;
}
.job-detail {
  cursor: pointer;
  padding: 19px 24px 0px 22px;
}
.big {
  cursor: context-menu;
  max-height: 579px;
  overflow-y: scroll;
}
.card .card-body .card-title {
  font-weight: 700;
  font-size: 18px;
}
.card:not(.side-card) {
  line-height: 26px;
}
.side-card {
  margin: 0px 16px 16px 16px;
  line-height: 24px;
}
.m-detail {
  margin-right: 10px;
}
.side-card {
  margin: 0px 16px 16px 16px;
}
.side-card .b-detail p {
  line-height: 24px;
  font-size: 14px;
}
.side-card .card-subtitle {
  line-height: 22px;
}
.job-detail .m-detail {
  font-size: 12px;
  font-weight: 500;
}
.job-detail .b-detail ul {
  font-size: 14px;
  color: #666666;
}
.job-detail .card-subtitle {
  padding: 0 0 10px 0;
  font-weight: 600;
}
.sticky-footer-action {
  z-index: 11;
  padding: 25px 17px;
  box-shadow: -3px -8px 10px -3px #0000000d;
}
.list-group-flush > .list-group-item {
  border: none;
  display: list-item;
  margin-left: 20px;
  padding: 5px;
}
.list-group-flush > .list-group-item {
  border: none;
}
/* icons */
img.ch-icons {
  padding: 0 8px;
}
.job-detail .m-detail:first-child img.ch-icons {
  padding-left: 0;
}
.job-detail .m-detail:last-child img.ch-icons {
  padding-right: 0;
}
.ab-icons {
  position: absolute;
  left: 35px;
}
.job-detail .action-icons img.ch-icons {
  padding: 0 10px;
} /* Custom Buttons */
.button1 {
  width: 59px;
  height: 24px;
  font-family: Roboto;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 16px;
}
/* Custom Style */
.f12 {
  font-size: 12px;
}
.f14,
h6,
.h6 {
  font-size: 14px;
}
.f16,
h5,
.h5 {
  font-size: 16px;
}
.f18,
h4,
.h4 {
  font-size: 18px;
}
.f20,
h3,
.h3 {
  font-size: 20px;
}
.bb {
  font-weight: bold;
}
h6 {
  font-size: 14px;
}
.lh-1 {
  line-height: 16px;
}
.pl-30 {
  padding-left: 2rem;
}
.border-3 {
  border-radius: 3px;
}
.right-0::after {
  right: 0px !important;
}
.fw300 {
  font-weight: 300 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.sticky-header {
  position: sticky;
  top: 1%;
}
/* input */
.theme-input-group .input-right-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da;
  border-radius: 0 2px 2px 0;
}
.theme-input-group .form-control {
  border-radius: 2px;
  border: 2px solid #ced4da;
}
.theme-input-group .form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 2px solid;
  border-color: #f18343;
  outline: 0;
  box-shadow: none;
}
.multi-check .list-group-item {
  position: relative;
  display: block;
  padding: 0.3rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.dropdown .absolute-right {
  position: absolute;
  top: -21px;
  right: 17px;
}
/* buttons */
.btn-rounded {
  border-radius: 20px;
}
.btn-outline {
  background: no-repeat;
  color: #f18343;
  border: 1px solid #f18343;
}
.sidebarBtn {
  margin-top: -9px;
}

.filter-count {
  background-color: rgba(241, 131, 67, 0.108774);
  color: #f18343;
  font-size: 14px;
  font-weight: 700;
  padding: 3px 0px 0px 5px;
  border-radius: 50%;
  margin-right: 20px;
  width: 26px;
  height: 26px;
}

.form-control:focus + .search-list,
.search-list.active {
  display: block;
}

.input-search {
  outline: none;
}
.append-sign {
  position: absolute;
  top: 7px;
  left: 16px;
  z-index: 11;
}
.input-custom {
  font-size: 14px;
  height: 39px;
}

.dropdown-toggle::after {
  content: unset;
}

.dropdown-arrow {
  position: absolute;
  right: 24px;
  top: 6px;
}
.input-icon {
  position: absolute;
  left: 9px;
  right: unset;
  top: 6px;
  width: 16px;
  cursor: pointer;
}
.dropdown-arrow-sm {
  top: 37px;
}

.r-40 {
  right: 40px;
}

.r-15 {
  right: 15px;
}
.fs-16 {
  font-size: 1rem;
}
.dropdown {
  li {
    cursor: pointer;
    &:hover {
      background-color: unset;
      color: #f18343;
    }
    &.selected {
      color: #f18343;
    }
  }
}

.input-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
  font-size: 0.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
}
.form-check-input-ml {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}

.job-card-small-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.checkbox-select-filter-box {
  .checkbox-select-filter-box__control {
    box-shadow: none;
    &.checkbox-select-filter-box__control--is-focused {
      border-color: #f18343;
    }
  }
  .checkbox-select-filter-box__menu {
    position: unset;
    z-index: unset;
  }
  .checkbox-select-filter-box__multi-value__label {
    color: white;
  }
  .checkbox-select-filter-box__multi-value {
    background-color: #f18343 !important;
    color: white;
  }
}

.checkbox-select-filter-box__indicators,
.checkbox-select-filter-box__multi-value {
  cursor: pointer;
}

.checkbox-select-filter-box__placeholder {
  position: absolute;
}

.card-link {
  width: 100%;
  color: black;
  position: relative;
  cursor: default;
  &:hover {
    color: black;
    text-decoration: none;
  }
  &.greyed-out {
    cursor: default !important;
    div {
      cursor: default !important;
    }
    .card {
      .card-header,
      .card-body {
        pointer-events: none;
      }
      .remove-text {
        position: absolute;
        top: 55%;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        color: blue;
        text-decoration: blue underline;
        z-index: 5;
      }
      &::after {
        pointer-events: none;
        content: 'This job is no longer available';
        font-weight: 700;
        text-align: center;
        color: rgb(100, 100, 100);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        cursor: pointer;
        top: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
        background-color: rgba(255, 255, 255, 0.596);
      }
    }
  }
}
.detail-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort-by {
  transition: all 0.15s ease-in;
  &.rotate {
    transform: rotate(-180deg);
  }
}
