.btn-input {
  padding: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0;
  height: 2.375rem;

  &:hover {
    border-color: #ced4da;
    background-color: white;
    color: #f18343;
  }

  &:active {
    background-color: white !important;
    border-color: #ced4da !important;
    color: #f18343;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.input-btn-middle {
    border-left: none;
    border-right: none;

    &.is-invalid {
      border-color: #dc3545;
    }
  }

  &.input-btn-end {
    border-left: none;
    border-right: 1px solid #ced4da;

    &.is-invalid {
      border-color: #dc3545;
    }
  }
}
