.detail-sidebar {
  &.card {
    border: 1px solid #ededed;
  }
  .card {
    border: 1px solid #ededed;
  }

  .header {
    border-bottom: 1px solid #ededed;
  }
  .items-list {
    .active {
      border-left: 4px solid #f18343;
    }

    .item-content {
      font-size: 0.875rem;
      -webkit-line-clamp: 5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      &.font-weight-bold {
        -webkit-line-clamp: 3;
      }
    }
  }
}
