@import 'checkBox';

.border-rounded {
  border-radius: 0.25rem;
}

.profile-page {
  padding-right: 20px;
  .bg-white.border-grey {
    padding: 0.75rem 1.25rem;
  }
  .profile-header {
    .left-side {
      .position-relative {
        img.m-0 {
          width: 60px;
          height: 60px;
          border: 2px solid #f18343;
          object-fit: contain;
          border-radius: 50%;
        }
        .edit {
          width: 22px;
          height: 22px;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #f18343;
          border-radius: 50%;
          img {
            width: 12px;
            height: 12px;
            transform: translateY(35%) translateX(10%);
          }
        }
      }
      .avatar-text:nth-of-type(1) {
        font-weight: 700;
        font-size: 1.125rem;
      }
      .avatar-text:nth-of-type(2) {
        font-size: 0.875rem;
      }
    }
    .right-side {
      .text-bold {
        font-weight: 700;
      }
    }
  }
  .btn.btn-delete {
    &:hover {
      color: red;
    }
  }
  .form-label {
    font-size: 0.9375rem;
    font-weight: 700;
  }
  .profile-link {
    font-size: 1rem;
    text-decoration: none;
    &:not(:last-of-type) {
      border-bottom: dashed 1px #cdcdcd;
    }
    &:not(.active) {
      color: #333333;
    }
  }
  .w-320px {
    height: fit-content;
  }
  .info-page {
    h3 {
      font-weight: 700;
      font-size: 1rem;
    }
    h5 {
      font-size: 0.875rem;
    }
  }
  .profile-details {
    h3 {
      font-size: 1rem;
      font-weight: 700;
    }
    h5,
    h6 {
      font-size: 0.875rem;
    }
    .dash-border.position-relative {
      &:hover {
        .download-cv {
          opacity: 1;
          pointer-events: visible;
        }
      }
      .download-cv {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.932);
        opacity: 0;
        pointer-events: none;
        transition: all 0.15s ease-in;
      }
      .close-icon {
        z-index: 10;
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.664);
  }
  .recruiter-profile {
    position: relative;
    h4 {
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    .form-label {
      font-weight: lighter;
    }
    .about-company {
      .col {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .position-absolute {
      right: 2rem !important;
      top: 1rem !important;
    }
    .position-relative {
      img.m-0 {
        width: 60px;
        height: 60px;
        border: 2px solid #f18343;
        object-fit: contain;
        border-radius: 50%;
      }
      .edit {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #f18343;
        border-radius: 50%;
        img {
          width: 12px;
          height: 12px;
          transform: translateY(35%) translateX(10%);
        }
      }
    }
  }
}

.profile-modal.show {
  z-index: 95 !important;
}

.modal-content {
  h3.fw-700 {
    font-size: 1.5rem;
    line-height: 2rem;
    .d-inline-block {
      max-width: 100%;
      vertical-align: bottom;
    }
  }
  p.mb-4 {
    font-size: 1rem;
    &:not(.text-grey) {
      font-size: 1.125rem;
    }
  }
}

.checkbox-select__control {
  box-shadow: none !important;
}
.checkbox-select__control--is-focused {
  border-color: #f18343 !important;
}
.is-invalid {
  box-shadow: none;
}

.checkbox-select__indicators {
  cursor: pointer;
}
