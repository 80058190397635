body.bg-white {
  .main-container {
    background-color: #fff;
  }
}

.draft,
.recent {
  position: absolute;
  top: -8px;
  right: -12px;
  background-color: #f18343;
  color: #fff;
  padding: 3px;
  height: 13px;
  border-radius: 10px;
  text-align: center;
  line-height: 8px;
  font-size: 10px;
}

.my-jobs {
  position: relative;
  width: 100%;
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.664);
  }
  .my-jobs-placeholder {
    height: 60vh;
    width: 100%;
    .btn-primary {
      padding: 0.5rem 1.5rem;
    }
  }
  .posted-jobs {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    table {
      width: 100%;
      table-layout: fixed;
      overflow-wrap: break-word;
      border-radius: 0.25rem;
      .smart-ellipse {
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      thead {
        border-radius: 0.25rem;
        overflow: hidden;
        background-color: #f4f4f4;
        height: 52px;
        th {
          padding: 0 1.25rem;
        }
      }
      tbody {
        tr {
          height: 54px;
          &:hover {
            background-color: #f6f6f6;
            cursor: pointer;
            transition: all 0.15s ease-in;
          }
          &.new {
            background: linear-gradient(
                0deg,
                rgba(241, 131, 67, 0.0366587),
                rgba(241, 131, 67, 0.0366587)
              ),
              #ffffff;
            &:hover {
              background: #f6f6f6;
            }
          }
          td {
            padding: 0 1.25rem;
            .new-badge {
              position: absolute;
              top: 50%;
              left: -35px;
              width: 48px;
              text-align: center;
              height: 20px;
              transform: rotate(-90deg) translateX(22%);
              background-color: rgba(241, 131, 67, 0.514);
              box-shadow: 0px 0px 2px rgba(241, 131, 67, 0.284036);
              border: 0.5px solid rgba(241, 131, 67, 0.0888);
              color: #f18343;
              border-radius: 6px 6px 0px 0px;
            }
            .actions {
              .ml-3 {
                transform: rotate(90deg);
              }
              .dropdown-menu {
                .dropdown-item {
                  font-size: 0.85rem;
                  &:focus {
                    color: #212529;
                  }
                  &:hover {
                    background-color: rgba(0, 0, 0, 0.062) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Create Job Modal

.create-job-modal.modal {
  margin: 0 !important;
  padding: 0 !important;
  .modal-dialog {
    max-width: unset;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 3rem !important;
    width: 100%;
    .modal-content {
      min-height: calc(100vh - 3rem);
      border: none;
      background-color: #fcfcfc;
      border-radius: 50px 50px 0 0;
      transform: translateY(100vh);
      animation: slideUp 0.3s linear 1 forwards;
      .checkbox-select__control {
        box-shadow: none;
      }
      .checkbox-select__control--is-focused {
        border-color: #f18343;
      }
      .is-invalid {
        box-shadow: none;
      }
      .create-job,
      .edit-job {
        width: 50%;
        margin: 0 auto;
        .radio-container {
          display: block;
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .text-truncate {
            font-size: 0.85rem;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked {
              & ~ .checkmark {
                border-color: #f18343;
              }
              & ~ .checkmark:after {
                display: block;
                background-color: #f18343;
              }
            }
          }
          .checkmark {
            position: absolute;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 50%;
            &:after {
              content: '';
              position: absolute;
              display: block !important;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
