.cardContainer {
  div:first-child {
    margin-right: 2rem;
  }
}

.customCard {
  border: 1px solid rgba(151, 151, 151, 0.226399);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.0808566);
  border-radius: 10px;
}

.sw-description {
  display: none;
}

@media (max-width: 576px) {
  .sw-padding {
    padding: 1rem;
  }
  .sw-jobtype {
    justify-content: flex-start;
    flex-direction: column;
    .sw-jt-btn {
      height: unset;
      margin: 1rem 0;
      padding: 1.325rem 1.125rem;
      &:nth-of-type(2) {
        margin-top: 0;
      }
      width: 100%;
      .sw-frame span {
        display: none;
      }
      display: flex;
      align-items: center;
      img {
        width: 132px;
        max-height: 110px;
      }
      .sw-description {
        display: block;
        h4 {
          font-size: 18px;
          font-weight: 600;
          color: #333333;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  .sw-headings.sw-top-padding {
    text-align: start;
    h1 {
      font-size: 32px;
      font-weight: 500;
    }
    h3 {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
