.line {
  background-color: rgba(151, 151, 151, 0.33);
  height: 1px;
}

.link-primary {
  color: #f18343;
  cursor: pointer;
}

.tooltip-shadow {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
  -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
}

.jtx-signin-img {
  background-image: url('../../images/sign-in.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}
.custom-error-sign-in {
  width: 100%;
  font-size: 0.8rem;
  color: #dc3545;
  font-weight: 500;
}

.is-invalid:focus {
  box-shadow: none !important;
}

.custom-label {
  display: none;
}

.responsive-logo {
  width: 100vw;
  background-color: #f5f5f5;
}

.sign-in-content {
  width: min-content;
  min-width: 51%;
}

@media (max-width: 768px) {
  .sign-in-content {
    width: 100%;
  }
  .jtx-signup-img.terms {
    display: none;
    & + div {
      button {
        width: 45% !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .custom-label {
    display: block;
    top: 5px;
    left: 8px;
    font-size: 12px;
    font-weight: 500;
    opacity: 0;
    color: rgba(0, 0, 0, 0.6);
    background-color: #fff;
    z-index: 10;
    padding: 0 2px;
    transition: all 0.15s ease-in;
    &.show {
      top: -10px;
      opacity: 1;
    }
  }
  .signup-button:disabled {
    background-color: #cdcdcd;
    border-color: #cdcdcd;
    color: #8a8a8b !important;
  }
  form.sign-in {
    .form-control {
      min-height: 56px;
      & ~ div .input-group-append button,
      & ~ .input-group-append button {
        min-height: 56px;
      }
    }
    input:focus {
      box-shadow: none;
      & + .custom-label {
        top: -10px;
        opacity: 1;
        color: #f18343;
      }
    }
    input.is-invalid {
      & + .custom-label {
        color: #dc3545;
      }
    }
    input:focus::placeholder {
      color: transparent;
    }
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    input:focus:-moz-placeholder {
      color: transparent;
    }
    input:focus::-moz-placeholder {
      color: transparent;
    }
    input:focus:-ms-input-placeholder {
      color: transparent;
    }
  }
  .sign-in-border-bottom {
    font-size: 22px;
    padding-bottom: 1.4rem;
    margin-bottom: 1rem;
    &::after {
      content: '';
      width: 100vw;
      height: 1px;
      background-color: rgba(151, 151, 151, 0.33);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-1rem);
    }
  }
}
