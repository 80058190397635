.additional-info {
  label.bb {
    font-size: 14px;
  }
  .info-label {
    padding-bottom: 3px;
  }
}

.actions {
  .dropdown-menu {
    .dropdown-item {
      font-size: 0.85rem;
      &:focus {
        color: #212529;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.062) !important;
      }
    }
  }
}

.px-3 {
  h5.bb {
    font-size: 14px;
  }
  p {
    font-weight: lighter;
  }
  &.date {
    div.d-flex {
      background-color: #f8f8f8;
      p {
        font-size: 12px;
      }
      .author {
        color: #333333;
        text-decoration: underline;
      }
    }
  }
}

.job-detail-card {
  .mb-0.font-weight-bold.cursor-pointer {
    font-size: 0.875rem;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
