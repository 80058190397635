.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.disable-hover {
  &:hover {
    background-color: unset;
    color: #f18343;
  }
  &:focus {
    border: unset;
    color: #f18343;
  }
}

.row-2 {
  .col:first-child {
    padding-right: 14px;
  }
  .col:last-child {
    padding-left: 0px;
  }
}

.step-label {
  margin-left: 10px;
}

// body {
//   margin: 0;
//   font-size: 14px;
//   color: #333333;
//   background-color: #e1e1e1;
//   font-family: "Roboto", sans-serif;
// }
/* utility */
.s-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.c-padding {
  padding: 20px;
}
.cx-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.cy-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
.c-margin {
  margin: 20px;
}
.cx-margin {
  margin-left: 20px;
  margin-right: 20px;
}
.cy-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.w-320px {
  width: 325px;
}
.sep-left {
  border-right: 2px solid rgba(151, 151, 151, 0.19564);
  margin-right: 25px;
  padding-right: 25px;
}
.sep-sm-left {
  border-right: 2px solid rgba(151, 151, 151, 0.19564);
  margin-right: 10px;
  padding-right: 10px;
}
.left-bar {
  border-radius: 2px 4px 4px 2px;
  border-left: 3px solid #f18343;
}
.imp-info {
  border: 1px solid #f18343;
  border-radius: 2px 4px 4px 2px;
  border-left: 3px solid #f18343;
  padding: 16px 32px 16px 50px;
  background: #fefaf7;
  margin-bottom: 26px;
}
.b-detail + .seprator {
  margin: 10px 18px 0px;
}
.seprator {
  width: 100%;
  border-bottom: 2px solid #ededed;
}
.table-data {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 4px;
}
.notification-count {
  display: block;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  text-align: center;
  background: #fdf2eb;
  color: #f4b590;
  border-radius: 15px;
  position: absolute;
  top: 18px;
  right: 50px;
}
.tost-notication {
  position: absolute;
  top: 100px;
  right: 0;
  background: #f18343;
  color: #fff;
  padding: 16px;
  z-index: 11;
}
ul.nav.nav-pills > li {
  position: relative;
}

.saved-count {
  background: #f18343;
  color: #fff;
  display: block;
  width: 21px;
  height: 13px;
  border-radius: 6px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: -9px;
  line-height: normal;
  right: 0;
}
[type='range'] {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  padding: 0;
  margin: 14px 0;
  background: #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f18343;
  box-shadow: #f18343 1px 0px 6px;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1257px;
  }
}

/*nav */
nav.navbar {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.navbar .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
  padding-bottom: 10px;
  padding-top: 10px;
}
nav .navbar-nav > li.nav-item {
  position: relative;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #f18343;
  background: none;
}
.border-gradient {
  border-bottom: solid;
  border-width: 3px;
  // border-image: linear-gradient(
  //     90deg,
  //     rgb(255 255 255),
  //     rgb(255 171 131),
  //     rgb(255 255 255)
  //   )
  //   1;
}
@media (min-width: 768px) {
  .navbar-light .navbar-nav .nav-link.active::before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -18px;
    left: 0;
    background: #f18343;
  }
}
/*colors & backgrund*/
.bg-white {
  background: #fff;
}
.light-bg {
  background: #f0f0f063;
}
.no-bg {
  background: unset;
  background-color: unset;
}
.card-status.bg-info {
  background: rgba(67, 192, 241, 0.18) !important;
  color: #2289b1;
}
.card-status.bg-danger {
  background: rgba(241, 131, 67, 0.177393) !important;
  color: #ef6f24;
}
/* accordion */
.badge {
  background-color: #e6e6e6;
  color: #333;
  font-weight: normal;
  margin: 4px;
  font-size: 12px;
}
.badge-medium {
  padding: 0.4em 0.6em;
}
.badage-list .badge:first-child {
  margin-left: 0;
}
.badage-list .badge:last-child {
  margin-right: 0;
}
.badage-list:not(.b-0) {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.2rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
}

.badage-list .badge .badge-close {
  display: none;
  transition: 0.15s ease-in-out;
}
.badage-list .badge:hover .badge-close {
  display: inherit;
  width: 10px;
  transition: 0.15s ease-in-out;
  animation-timing-function: ease-in-out;
}

.accordion-padding {
  padding: 10px;
}
.accordion-controller .card .card-header {
  background: #fff;
  border: none;
}
.accordion-controller.no-sidebar .card-header {
  padding: 0;
}
.accordion-controller .card .card-header > h5 {
  font-size: 16px;
}
.accordion-controller .card .card-header > h5 .accordion-button {
  font-size: 16px;
  font-weight: 600;
}
.accordion-controller:not(.no-sidebar) .card .card-header {
  padding: 0;
  border-bottom: dashed 1px #cdcdcd;
}
.accordion-controller .card {
  border-radius: 0;
  border: none;
  position: relative;
}
.accordion-button::after {
  display: inline;
  width: 1.25rem;
  position: absolute;
  right: 22px;
  height: 1.25rem;
  content: ' ';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}
.accordion-controller:not(.no-sidebar) .card {
  padding: 1px 26px;
}
.accordion-controller .card-body {
  padding: 0;
}
.accordion-controller .card:last-child .card-header {
  border: none;
}
button[data-toggle='collapse'] {
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 12px 0;
  font-size: unset;
}
/* tabs */

.nav-link {
  color: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f18343;
  background: none;
  border-radius: 0;
  border-bottom: 3px solid #f18343;
}
.nav-link:focus,
.nav-link:hover {
  color: #f18343;
}
#pills-tab li {
  padding-right: 15px;
}

#pills-tab li a {
  font-size: 16px;
  padding: 0 0 20px 0;
}
ul.nav.nav-pills > li > a {
  padding: 0 15px 20px 16px;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
/* card */
.card-status {
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.card-title .card-subtitle {
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}
.card-border-bottom {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  margin-bottom: 16px;
}
.card .job-detail .b-detail {
  padding-bottom: 24px;
}
.card-body {
  padding: 24px 19px;
}
.side-card .job-detail {
  padding: 16px 8px 0px 12px;
}
.job-detail {
  cursor: pointer;
  padding: 19px 24px 0px 22px;
}
.big {
  cursor: context-menu;
  max-height: 579px;
  overflow-y: scroll;
}
.card .card-body .card-title {
  font-weight: 600;
  font-size: 18px;
}
.card:not(.side-card) {
  line-height: 26px;
}
.side-card {
  margin: 0px 16px 16px 16px;
  line-height: 24px;
}
.side-card {
  margin: 0px 16px 16px 16px;
}
.side-card .b-detail p {
  line-height: 24px;
  font-size: 14px;
}
.side-card .card-subtitle {
  line-height: 22px;
}
.job-detail .m-detail {
  font-size: 12px;
  font-weight: 500;
}
.job-detail .b-detail ul {
  font-size: 14px;
  color: #666666;
}
.job-detail .card-subtitle {
  padding: 0 0 10px 0;
  font-weight: 600;
}
.sticky-footer-action {
  z-index: 11;
  padding: 25px 17px;
  box-shadow: -3px -8px 10px -3px #0000000d;
}
.list-group-flush > .list-group-item {
  border: none;
  display: list-item;
  margin-left: 20px;
  padding: 5px;
}
.list-group-flush > .list-group-item {
  border: none;
}
/* icons */
img.ch-icons {
  padding: 0 8px;
}
.job-detail .m-detail:first-child img.ch-icons {
  padding-left: 0;
}

.job-detail .m-detail:last-child img.ch-icons {
  padding-right: 0;
}
.ab-icons {
  position: absolute;
  left: 35px;
}
.job-detail .action-icons img.ch-icons {
  padding: 0 10px;
} /* Custom Buttons */
.button1 {
  width: 59px;
  height: 24px;
  font-family: Roboto;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 16px;
}

/* Custom Style */
.f12 {
  font-size: 12px;
}
.f14,
h6,
.h6 {
  font-size: 14px;
}

.f16,
h5,
.h5 {
  font-size: 16px;
}

.f18,
h4,
.h4 {
  font-size: 18px;
}

.f20,
h3,
.h3 {
  font-size: 20px;
}

.bb {
  font-weight: bold;
}

h6 {
  font-size: 14px;
}
.pl-30 {
  padding-left: 2rem;
}
.border-3 {
  border-radius: 3px;
}
.right-0::after {
  right: 0px !important;
}
/* input */
.theme-input-group .input-right-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da;
  border-radius: 0 2px 2px 0;
}
.theme-input-group .form-control {
  border-radius: 2px;
  border: 2px solid #ced4da;
}
.theme-input-group .form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 2px solid;
  border-color: #f18343;
  outline: 0;
  box-shadow: none;
}
.multi-check .list-group-item {
  position: relative;
  display: block;
  padding: 0.3rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.dropdown-arrow {
  position: absolute;
  right: 24px;
  top: 6px;
}
.input-custom {
  font-size: 14px;
  height: 39px;
}

.input-icon {
  position: absolute;
  left: 9px;
  right: unset;
  top: 6px;
  width: 16px;
  cursor: pointer;
}
.dropdown-arrow-sm {
  top: 37px;
}

/* buttons */
.btn-rounded {
  border-radius: 20px;
}
.btn-outline {
  background: no-repeat;
  color: #f18343;
  border: 1px solid #f18343;
}
.sidebarBtn {
  margin-top: -9px;
}

/* setup Wizard */
.w-485px {
  width: 485px;
}
.w-mx-100 {
  max-width: 100%;
}
.bg-primary {
  background: #f18343;
}
.vh-100 {
  min-height: 100vh;
  height: auto !important;
}
.vw-100 {
  min-width: 100vw;
  width: auto !important;
}
.w-100by485 {
  min-width: calc(100% - 485px);
  width: auto;
}
.swt-padding {
  padding-top: 27px;
}
.swb-padding {
  padding-bottom: 27px;
}
.swl-padding {
  padding-left: 70px;
}
.swr-padding {
  padding-right: 70px;
}
.sw-padding {
  padding: 27px 70px;
}
.sw-steps {
  padding: calc(125px - 39px) 0;
  padding-top: 110px;
}
.sw-steps ul li {
  padding-left: 0;
}
.sw-top-padding {
  padding-top: calc(180px - 27px);
}
.sw-steps ul > li {
  color: #fff;
  text-transform: uppercase;
}
.sw-steps .sw-icon {
  padding-right: 5px;
}
.sw-steps .sw-icon + h4 {
  display: inline-block;
  position: relative;
  top: 2px;
}
.sw-headings h1 {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}
.sw-headings h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
}
.sw-jobtype {
  display: flex;
}
.sw-jobtype .sw-jt-btn {
  border-radius: 8px;
  width: 224px;
  height: 263px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 27px 30px 0;
  text-decoration: none;
  box-shadow: 0 0 13px 3px #00000012;
}
.sw-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sw-model-form {
  padding: 20px 0px;
}
.sw-frame > span {
  padding-top: 15px;
  font-size: 18px;
  color: #000;
}
.overflowx-hidden {
  overflow-x: hidden;
}
.sw-form {
  padding-top: 46px;
  max-width: 614px;
}
.bin-icon {
  display: flex;
  justify-content: center;
  position: relative;
  top: 40px;
}
.sw-model-btn-close {
  padding: 8px 18px;
  border-radius: 25px;
  border: 1px solid #d0d0d0;
}
.sw-save-btn {
  padding: 8px 18px;
  border-radius: 25px;
  background: #f18343;
  color: #fff;
}
.sw-save-btn:hover {
  background: #f18343;
  color: #fff;
}
.sw-addmore {
  color: #f18343;
  background: #f1834317;
}
.sw-addmore:hover {
  background: #f18343;
  color: #fff;
}
.sw-addmore:hover img {
  filter: brightness(0) invert(1);
}
label {
  font-size: 16px;
}
.tags-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  height: auto;
}
.form-control:not {
  border-radius: 1px;
  border: 1px solid #b3b3b3;
}
.form-control:not(.mb-0) {
  margin-bottom: 1rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
}
.form-control:focus {
  border-color: #f18343;
  box-shadow: none;
}
form h1,
form h2,
form h3,
form h4,
form h5,
form h6 {
  line-height: unset;
}
.sw-action {
  padding: 24px 0;
}
.sw-btn-next {
  background: #f18343;
  color: #fff;
  padding: 8px 18px;
  border-radius: 25px;
}
.btn-primary:not(.btn-outline):hover,
.sw-btn-next:not(.btn-outline):hover {
  color: #fff;
}
.btn-outline.btn-primary:hover,
.btn-outline.sw-btn-next:hover {
  color: #fff;
  background-color: #f18343;
  border-color: #f18343;
}
.sw-btn-next img {
  filter: brightness(0) invert(1);
}
.sw-btn-default {
  color: #333333bf;
}
.sw-btn-default:hover {
  color: #f18343;
}
.summary-data {
  padding: 12px 24px 0 24px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow-y: scroll;
}
.summary-data {
  p {
    font-size: 14px;
  }
  h6 {
    font-size: 18px;
  }
}
.mh200 {
  max-height: 200px;
}

.summary-data .row {
  padding-bottom: 10px;
}
.summary-data .card-subtitle {
  margin: 0;
}

.summary-body {
  width: 300px;
  max-height: 1500px;
}

.dropdown-accordion .card-header {
  padding: 0;
  border-bottom: none;
  background: none;
}
.dropdown-accordion .card {
  border: none;
  border-radius: 0;
}

.modal-body {
  padding: 2rem;
}
.modal-body .duplicate-section .custom-col-1 {
  flex: 0 0 250px;
  padding-right: 12px;
  box-sizing: content-box;
}

.modal-body .duplicate-section .custom-col-2 {
  flex: 0 0 16px;
  padding-left: 14px;
}
#customModal1 {
  background-color: rgba(0, 0, 0, 0.509725);
}
#customModal1 .form-control:not(.mb-0) {
  margin-bottom: 23px;
}
#customModal1 .sw-model-form {
  padding-bottom: 0;
}
#customModal1 .sw-action {
  padding-bottom: 0;
}
.duplicate-section {
  max-height: 465px;
  min-height: 315px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}
.search-list-sm {
  left: 0px !important;
  width: calc(100% - 12px) !important;
  top: 70px !important;
}

.search-trigger + .search-list {
  z-index: 1;
  display: none;
  background: #fff;
  padding: 0 15px;
  margin-right: 15px;
  border-radius: 0px;
  position: absolute;
  max-height: 200px;
  width: calc(100% - 30px);
  overflow-y: auto;
  box-shadow: 0 0 6px -3px #000;
  top: 39px;
  left: 15px;
}
input[type='checkbox']:checked:after {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzI2NSAtNjUyKSI+PHJlY3Qgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MjY1LjUgNjUyLjUpIiBmaWxsPSIjZjE4MzQzIiBzdHJva2U9IiNmMTgzNDMiIHN0cm9rZS13aWR0aD0iMSIvPjxwYXRoIGQ9Ik01NzUsNzE3LjgxMmw0LjIxOCwzLjU3LDYuODktOS4zODIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY2OTQgLTU1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEiLz48L2c+PC9zdmc+);
  width: 20px;
  height: 20px;
  z-index: 99;
  left: -7px;
  top: -2px;
  position: absolute;
}
input[type='checkbox']:after {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cmVjdCB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIiBmaWxsPSIjZmZmIiBzdHJva2U9IiNiM2IzYjMiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==);
  width: 20px;
  height: 20px;
  z-index: 99;
  left: -7px;
  top: -2px;
  position: absolute;
}
.search-trigger + .search-list li {
  padding: 10px 24px 14px 28px;
}
.search-list.active {
  display: block;
}

img.sw-board {
  position: fixed;
  bottom: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

div[tabindex='-1'] {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@media (min-width: 992px) {
  .modal-theme {
    max-width: 614px;
  }
}

@media (min-width: 1441px) {
  // img.sw-board {
  //   position: absolute;
  //   bottom: 0;
  // }
  // .sw-steps {
  //   padding: 30px 0;
  // }
  // .sw-top-padding {
  //   padding-top: 50px;
  // }
}
@media screen and (min-width: 1199px) and (max-width: 1200px) {
  // img.sw-board {
  //   position: absolute;
  //   bottom: 0;
  // }
  // .sw-steps {
  //   padding: 30px 0;
  // }
  // .sw-top-padding {
  //   padding-top: 50px;
  // }
}

@media screen and (max-height: 900px) {
  .sw-top-padding {
    padding-top: calc(100vh - 745px);
  }

  .sw-steps {
    padding-top: calc(100vh - 790px);
  }
}
/* setup Wizard End */

.modal.show {
  display: block !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.is-invalid .checkbox-select__control:hover,
.is-invalid .checkbox-select__control {
  border-color: #dc3545;
  background: none;
}

.is-invalid {
  border-color: #dc3545;
}

select.form-control {
  background: url(../../images/Icons/dropdown.svg) no-repeat right;
  -webkit-appearance: none;
  background-position-x: calc(100% - 10px);
}

select option:checked {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
}
select option:hover {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
  color: #fff;
}

.dropdown {
  li {
    cursor: pointer;
    &:hover {
      background-color: unset;
      color: #f18343;
    }
    &.selected {
      color: #f18343;
    }
  }
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: -1rem;
  font-size: 80%;
  color: #dc3545;
}

.search-list-popper.upwards {
  top: -105px !important;
}

.input-search {
  outline: none;
}
.append-sign {
  position: absolute;
  top: 7px;
  left: 28px;
  z-index: 11;
}
.search-trigger:focus-within {
  border: 1px solid #f18343;
}

.search-trigger.active-border {
  border: 1px solid #f18343;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.checkbox-select__multi-value {
  background-color: #f18343 !important;
  color: white;
  .checkbox-select__multi-value__label {
    color: white;
  }
}

.checkbox-select__placeholder {
  position: absolute;
}

.onboarding-progress {
  display: none;
}

@media (max-width: 1024px) {
  .w-485px.bg-primary.vh-100 {
    display: none;
  }
  .sw-jobtype {
    justify-content: center;
  }
  .sw-headings.sw-top-padding {
    text-align: center;
    padding-top: 1rem;
  }
}

@media (max-width: 768px) {
  .onboarding {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  form.about-candidate,
  form.about-recruiter {
    .checkbox-select__menu {
      z-index: 20;
    }
    .sw-form {
      padding-top: 24px;
    }
    input:focus::placeholder,
    textarea:focus::placeholder {
      color: transparent;
    }
    input:focus::-webkit-input-placeholder,
    textarea:focus::-webkit-input-placeholder {
      color: transparent;
    }
    input:focus:-moz-placeholder,
    textarea:focus:-moz-placeholder {
      color: transparent;
    }
    input:focus::-moz-placeholder,
    textarea:focus::-moz-placeholder {
      color: transparent;
    }
    input:focus:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder {
      color: transparent;
    }
    .form-group.position-relative {
      .form-label {
        display: none;
      }
      .checkbox-select__indicator {
        align-self: flex-start;
      }
      .checkbox-select__multi-value__remove {
        svg {
          fill: #f18343;
          background-color: #fff;
          border-radius: 50%;
        }
      }
      .checkbox-select__control,
      .form-control {
        border-radius: 2px !important;
        min-height: 54px;
      }
      input:focus,
      textarea:focus {
        box-shadow: none;
        & + .custom-label {
          top: -10px;
          opacity: 1;
          color: #f18343;
          max-width: 90%;
        }
      }
      .custom-label.select {
        height: 16px;
      }
      input.is-invalid,
      textarea.is-invalid,
      div.is-invalid {
        & + .custom-label {
          color: #dc3545;
        }
      }
    }
    .sw-action {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 1rem;
      z-index: 15;
      width: 100vw;
      background-color: #fff;
      .sw-btn-default {
        background-color: rgba(0, 0, 0, 0.03);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-left: 1rem;
        .chevron.left {
          display: block;
          width: 0.7rem;
          height: 0.7rem;
          border-color: rgba(0, 0, 0, 0.87);
          &::before {
            margin-left: 7px;
          }
        }
      }
      .sw-btn-next {
        height: 56px;
        border-radius: 92px !important;
        min-width: 215px;
        width: 100%;
        img {
          display: none;
        }
      }
      .onboarding-progress {
        display: block;
        width: 100vw;
        height: 4px;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.03);
        &::after {
          content: '';
          transition: left 0.15s ease-in;
          position: absolute;
          top: 0;
          left: -100%;
          height: 100%;
          width: 100%;
          border-radius: 4px;
          background-color: rgba(241, 131, 67, 1);
        }
        &.step-1 {
          &::after {
            left: -66%;
          }
          &.recruiter {
            &::after {
              left: -50%;
            }
          }
        }
        &.step-2 {
          &::after {
            left: -33%;
          }
          &.recruiter {
            &::after {
              left: 0;
            }
          }
        }
        &.step-3 {
          &::after {
            left: 0;
          }
        }
      }
    }
    .summary-data {
      padding: 10px;
      background-color: rgba(241, 241, 241, 1);
      .row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        &:not(:first-of-type) {
          margin-bottom: 0.7rem;
        }
        div[class^='col'] {
          padding-right: 0;
          padding-left: 0.3rem;
          .badge.badge-medium {
            margin-left: 0;
            font-weight: 600;
          }
        }
        .col-6,
        .col-5 {
          padding-left: 0rem !important;
          .card-subtitle:first-of-type {
            color: rgba(136, 136, 136, 1);
            font-weight: 500;
          }
        }
        .summary-body {
          width: unset;
          max-height: unset;
        }
      }
    }
  }
}
