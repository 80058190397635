.recruiter-detail {
  .loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.596);
  }
  .card {
    padding: 41px 48px;
    .recruiter-avatar {
      width: 119px;
      height: 119px;
      border-radius: 50%;
      object-fit: contain;
    }
    .name-designation {
      h3 {
        font-size: 1.5rem;
        font-weight: 700;
      }
      h4 {
        font-weight: 500;
        font-size: 1.125rem;
      }
      p {
        font-size: 0.875rem;
        color: #979797;
      }
    }
    &.job {
      border-radius: 5px;
      &.active {
        border-left: 4px solid #f18343;
      }

      .item-content {
        font-size: 0.875rem;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &.font-weight-bold {
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
