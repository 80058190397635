// .form-control {
//   border-radius: 0 !important;
//   &:focus {
//     box-shadow: unset !important  ;
//     // border: 1px solid #f18343 !important;
//   }
// }

.form-select {
  border-radius: 0 !important;
}

.custom-alert {
  --toastify-toast-min-height: 56px;
  --toastify-icon-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.text-grey {
  color: #666666;
}

.text-light-gray {
  color: #bebebe;
}

.bg-grey {
  background-color: rgba(173, 173, 173, 0.112544);
}

.border-grey {
  border: 1px solid #ededed;
}
.modal-animate {
  transform: translateX(50%);
  animation: fadeIn 0.3s linear 1 forwards;
}

.fw-700 {
  font-weight: 700;
}

@keyframes fadeIn {
  100% {
    visibility: visible;
    transform: translateX(0);
  }
}
.pagination {
  button {
    color: #808080;
    font-size: 0.8rem;
    width: 2.15rem;
    border: none;
    background-color: unset;
    &.cursor-default {
      cursor: default !important;
    }
  }
  &.small {
    background-color: #ededed;
    border-radius: 0;
    span.d-block {
      font-weight: 600;
      font-size: 0.825rem;
    }
    button {
      font-size: 0.875rem;
    }
  }
}

.chevron::before {
  content: '';
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-width: 0.15rem 0.15rem 0 0;
  border-style: solid;
  position: relative;
  bottom: 50%;
}

.chevron.right:before {
  left: -5px;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 5px;
  transform: rotate(-135deg);
}

.preserve-line {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.salary-tooltip {
  transition: color 0.15s;
  &:hover {
    color: #f18343 !important;
  }
}

.tooltip-shadow {
  opacity: 1 !important;
}
