/* side pannel start*/
.right-slide {
  right: 0;
  top: 0;
}
.left-slide {
  left: 0;
  top: 0;
}
.slide-close {
  position: absolute;
  right: 24px;
  top: 40px;
}
.slide-container {
  position: absolute;
  width: 588px;
  min-height: 100vh;
  height: auto;
  padding: 40px 20px 0px 20px;
  z-index: 9;
  background-color: white;
}
.messages-loader {
  width: 588px;
  height: 100vh;
  position: fixed;
  background: #ffffff87;
  &.white-bg {
    background: #fff;
  }
  z-index: 10;
  left: 0;
  top: 0;
}
.slide-container .card-title {
  font-weight: 600;
  font-size: 16px;
}
.slide-container .slide-header {
  font-weight: 700;
  font-size: 24px;
  & + .card-subtitle {
    font-style: italic;
  }
}
.create-btn.checkbox-select__option--is-focused {
  &:hover {
    background-color: #fff !important;
  }
}
.create-job-btn {
  background-color: #fef1ea;
  color: #f18343;
  width: 100%;
  text-align: center;
  span {
    font-size: 1.5rem;
    line-height: 17px;
  }
  &:hover {
    background-color: #f8e7dd;
    color: #f18343;
  }
}

.message-info {
  height: calc(100vh - 190px);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.slide-container .m-content {
  color: #666666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 15px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.slide-container .m-card-title {
  font-size: 16px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-search input[type='search'] {
  border-radius: 4px;
  border: 1px solid #ededed;
  background: #adadad1f;
  color: #bebebe;
}

.recruiter-filters {
  .btn.btn-outline {
    border-color: #cccccc;
    color: #cccccc;
    &:hover {
      background-color: #f18343;
      border-color: #f18343;
      color: #fff;
    }
  }
}

.group-messages {
  .checkbox-select__option {
    cursor: pointer;
  }
  .checkbox-select__option--is-focused {
    background-color: #deebff !important;
  }

  .checkbox-select__value-container {
    max-height: 64px;
    overflow-y: scroll;
  }
  .checkbox-select__menu {
    div {
      background-color: #fff;
      color: black;
    }
  }
  .checkbox-select__multi-value__label {
    max-width: 6rem;
  }
  .checkbox-select__control {
    box-shadow: none;
    &:hover,
    &.checkbox-select__control--is-focused {
      border-color: #f18343;
    }
  }
}

.slide-container .nav-pills li a {
  text-transform: uppercase;
  font-weight: bold;
}
.slide-container .job-detail {
  padding: 15px;
  cursor: pointer;
}
.card-body::-webkit-scrollbar {
  display: none !important;
}
.slide-container .card .job-detail .b-detail {
  padding-bottom: 0;
}
.slide-container .job-detail .m-detail {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #666666;
}
.slide-container .slide-icon {
  padding: 15px 15px 15px 40px;
}
.slide-container .btn-ab-left {
  position: absolute;
}
.slide-container .slide-icon .m-detail {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #666666;
  display: inline-block;
}
.slide-header.job-title {
  font-size: 1.125rem;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.confirmation-buttons.messages {
  .btn {
    width: 115px;
    height: 40px;
  }
  .dot-menu {
    transform: rotateZ(90deg);
  }
}

.message-date {
  font-size: 11px;
  font-weight: 400;
  color: #9e9ea6;
}

.pl-large {
  padding-left: 2.5rem;
}

.emoji-toggler {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  width: 19px;
  height: 19px;
}

/* side pannel end*/

.dropdown-menu {
  min-width: 7rem;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  color: #f18343;
}

.mesages-container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.thread-container {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  overflow-x: hidden;
  &.large {
    height: calc(100vh - 220px);
  }
}

.message-input {
  width: 100%;
  height: 38px;
  padding-right: 25px;
  overflow-wrap: break-word;
  resize: none;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
  margin-right: 5px;
  span {
    width: fit-content;
    transform: translateX(-10%);
  }
}

.btn-chat {
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icons {
  height: 38px;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
}

.at-icon {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}

.emoji-icon {
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}
.chat-fixed-bottom {
  position: fixed;
  width: 540px;
  z-index: 100;
  bottom: 0;
}

.chat-emoji-container {
  position: absolute;
  bottom: 50px;
  right: 0px;
  z-index: 100;
}
.card-body.job-detail.active {
  border-left: 4px solid #f18343;
  border-radius: 0.25rem;
}

.application-message {
  max-width: 500px;
  padding-left: 0;
  p {
    margin-bottom: 0;
  }
  a {
    color: #0000ee;
    text-decoration: underline;
  }

  // p:first-of-type {
  //   color: #f18343;
  //   font-weight: bold;
  // }
}

.card-body.job-detail.greyed-out {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    cursor: pointer;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    background-color: rgba(255, 255, 255, 0.596);
  }
}
