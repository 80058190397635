.line {
  background-color: rgba(151, 151, 151, 0.33);
  height: 1px;
}

.link-primary {
  color: #f18343;
}

.tooltip-shadow {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
  -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
  -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.88);
}

.jtx-signup-img {
  background-image: url('../../images/sign-in.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.no-shadow {
  box-shadow: none !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}

.responsive-logo {
  width: 100vw;
  background-color: #f5f5f5;
}

@media (max-width: 575px) {
  * {
    font-family: 'Roboto';
  }
  .jtx-signup-img {
    display: none;
  }
  .forgot-pass-mobile {
    font-size: 26px;
    text-align: center;
    font-weight: bold;
  }
  .txt-mobile {
    font-size: 24px;
    font-weight: bold;
  }
  .forgot-pass-success-text {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 20px 0px 20px;
  }
  .sw-btn-next {
    border-radius: 0px !important;
  }
}
