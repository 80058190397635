.card-body.skills {
  overflow-x: scroll;
}
.card-body {
  .secondary-paragraph {
    -webkit-line-clamp: 5;
  }
  .primary-paragraph {
    -webkit-line-clamp: 3;
  }
  .secondary-paragraph,
  .primary-paragraph {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.card.applied {
  margin-top: 1.8rem !important;
}
