.social-btn {
  background-color: rgba(244, 244, 244, 0.547285);
  border-radius: 7px;
  color: #333333;
  border-color: rgba(240, 240, 240, 0.9);

  &:hover,
  &:focus,
  &:active {
    border-color: rgba(240, 240, 240, 0.9) !important;
    background-color: rgba(238, 237, 237, 0.9) !important;
    color: #333333 !important;
    box-shadow: none;
  }
}

@media (max-width: 480px) {
  .social-btn {
    .text-center {
      display: none;
    }
    .social-icon {
      width: 27px;
      height: 27px;
    }
    .d-flex {
      justify-content: center !important;
    }
  }
}
