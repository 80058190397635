.dash-border {
  border: dashed 1px rgba(0, 0, 0, 0.125);
}
.upload-zone {
  cursor: pointer;
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  &.image-upload {
    z-index: 10;
  }
}
.drag-over {
  background-color: rgba(0, 191, 165, 0.04);
}
.orange-border {
  border-color: #f08343;
}
h6 {
  font-size: 16px;
  font-weight: normal;
}
.card-body {
  padding: 20px 19px;
}

.popup-form {
  position: fixed;
  z-index: 115;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  p.mb-3.note {
    font-size: 1.125rem;
  }
  .btn.btn-primary:disabled {
    background-color: #d0d0d0;
    border-color: #d0d0d0;
    color: #757575 !important;
  }
  .content-holder {
    width: 500px;
  }
}

.dash-border.position-relative {
  &:hover {
    .download-cv {
      opacity: 1;
      pointer-events: visible;
    }
  }
  .download-cv {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.932);
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-in;
  }
  .close-icon {
    z-index: 10;
  }
}
