.card-header {
  .particulars {
    font-size: 0.75rem;
    div:first-of-type {
      img {
        padding-left: 0;
      }
    }
    & > div {
      margin-right: 0.5rem;
      p {
        max-width: 200px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .checkbox {
    .switch {
      height: 20px;
      transform: translateY(4px);
    }
    .slider {
      background-color: #f18343;
    }
    .slider:before {
      bottom: 3px;
    }
    span {
      font-size: 14px;
    }
    input:checked + .slider {
      background-color: #ccc;
    }
  }
  .applied-tag {
    position: absolute;
    height: 1.5rem;
    width: 5rem;
    top: -1.5rem;
    left: 0;
    background-color: rgba(67, 192, 241, 0.18);
    text-align: center;
    border-radius: 6px 6px 0px 0px;
    border: 0.5px solid rgba(67, 192, 241, 0.38);
    color: #2289b1;
  }
}
